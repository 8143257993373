import React from "react";
import ash2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/thisisengineering-raeng-64YrPKiguAE-unsplash.jpg";
import "./GraphnileLearning.css";
import { Container, Row } from "react-bootstrap";
import ai from "/Users/aicgim-imac/Desktop/webashpak/src/images/ai.jpg";
import bc from "/Users/aicgim-imac/Desktop/webashpak/src/images/bc.jpg";
import "./Button.css";
import { Helemt } from "react-helmet";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
const GraphnileLearning = () => {
  return (
    <section style={{ paddingBottom: "50px" }}>
      <Helmet>
        <title>Graphnile | Graphnile Learning</title>
      </Helmet>
      <Container style={{ paddingTop: "70px" }}>
        <h5>Graphnile Learning:</h5>

        <Row
          className="learning"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
          }}
        >
          <Link
            to="/finance"
            style={{ textDecoration: "none", color: "black" }}
          >
            <div className="media">
              <img
                style={{ width: "250px", marginLeft: "0px" }}
                className="mr-3"
                src={ash2}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 style={{ paddingTop: "25px" }} className="mt-0">
                  Certification in Restructuring and Disstressed Valution For
                  Special Situations. We are the team of highly qualified Quant
                  professionals who have worked in investment banking, risk
                  management, trading.
                </h5>

                <button className="btn">Read More</button>
              </div>
            </div>
          </Link>
        </Row>
        <Row
          className="learning"
          style={{
            paddingTop: "40px",
            paddingBottom: "20px",
            paddingLeft: "20px",
          }}
        >
          <a href="#" style={{ textDecoration: "none", color: "black" }}>
            <div className="media">
              <img
                style={{ width: "250px", marginLeft: "0px" }}
                className="mr-3"
                src={ai}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 style={{ paddingTop: "25px" }} className="mt-0">
                  Certification in Artifical Intelligence and Machine Learning.
                  Artificial intelligence (AI) brings with it a promise of
                  genuine human-to-machine interaction.
                </h5>
                <a
                  href="#"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <button style={{ marginTop: "15px" }} className="btn">
                    Read More{" "}
                  </button>
                </a>
              </div>
            </div>{" "}
          </a>
        </Row>
        <Row
          className="learning"
          style={{
            paddingTop: "40px",
            paddingBottom: "20px",
            paddingLeft: "20px",
          }}
        >
          <a href="#" style={{ textDecoration: "none", color: "black" }}>
            {" "}
            <div className="media">
              <img
                style={{ width: "250px", marginLeft: "0px", height: "16vh" }}
                className="mr-3"
                src={bc}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 style={{ paddingTop: "25px" }} className="mt-0">
                  Certification in Block Chain and Related Technologies.
                  blockchain skills are in such high demand, blockchain
                  certification holders can benefit in many ways.
                </h5>
                <a
                  href="#"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <button style={{ marginTop: "15px" }} className="btn">
                    Read More{" "}
                  </button>
                </a>
              </div>
            </div>{" "}
          </a>
        </Row>
      </Container>
    </section>
  );
};

export default GraphnileLearning;
