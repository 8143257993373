import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import economic from "/Users/aicgim-imac/Desktop/webashpak/src/images/pexels-kampus-production-8353790.jpg";
import "./RegulatoryRisk.css";
import { Helmet } from "react-helmet";
const RegulatoryRisk = () => {
  return (
    <section className="restructuringTurnaround">
      <Helmet>
        <title>Graphnile | Regulatory & Risk Advisory</title>
      </Helmet>
      <Container fluid className="restructuring ">
        <Container>
          <h4 style={{ paddingTop: "150px" }}>Regulatory & Risk Advisory</h4>
        </Container>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Row>
          <Col md={6}>
            <img style={{ width: "100%" }} src={economic}></img>
          </Col>

          <Col md={6}>
            <h5> Regulatory & Risk Advisory</h5>
            <p style={{ color: "grey" }}>
              Every geographic and industry sector is exposed to risks related
              to strategic, financial, operational, and compliance issues. The
              risks arise from business strategies, initiatives, processes,
              people, data, and technology as well as external conditions such
              as market forces, regulation, enforcement trends, and stakeholder
              activism. Our goal is to help companies look at risk and
              resilience in order to better prepare for the future through the
              implementation of smart changes.
            </p>
          </Col>
        </Row>

        <Row className="row1" style={{ paddingTop: "40px" }}>
          <h5>How We Help Clients</h5>
          <h6>Credit Risk</h6>
          <p style={{ color: "grey" }}>
            In addition to managing risk across the entire credit value chain,
            we assist financial institutions with credit portfolio management,
            loss mitigation, and credit modeling and advanced analytics.
          </p>
        </Row>

        <Row className="row2" style={{ paddingTop: "20px" }}>
          <h6>Risk Advanced Analytics</h6>
          <p style={{ color: "grey" }}>
            To help clients optimise their risk exposures, improve their
            performance, increase profits, and accelerate growth, we use
            advanced tools and techniques along with expertise in strategy
            formulation and organizational transformation. By leveraging
            analytics, we are improving our impact delivery model. We leverage
            the combined expertise of our consultants, modelers and data
            scientists, and quantitative experts.
          </p>
        </Row>

        <Row
          className="row3"
          style={{ paddingTop: "20px", paddingBottom: "30px" }}
        >
          <h6>Practice Area Specialties</h6>
          <p style={{ color: "grey" }}>
            Experts at Graphnile help clients implement new regulatory
            requirements and take advantage of new opportunities to grow their
            business. Regulations in financial services are changing with an
            alarming pace and complexity, often affecting business models and
            strategy. With extensive client experience and relationships with
            regulatory bodies across the globe, our remarkable team of senior
            practitioners and former regulators has a deep understanding of the
            regulatory landscape
          </p>
        </Row>
      </Container>
    </section>
  );
};

export default RegulatoryRisk;
