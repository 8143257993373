import Card from "react-bootstrap/Card";
import "./Button.css";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import rise from "/Users/aicgim-imac/Desktop/webashpak/src/images/rise2.jpg";
import media1 from "/Users/aicgim-imac/Desktop/webashpak/src/images/national-cancer-institute-dC_KGjeCId8-unsplash.jpg";
import media2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/arnaud-jaegers-IBWJsMObnnU-unsplash.jpg";

import Helmet from "react-helmet";
const MediaCoverage = () => {
  return (
    <section className="mediaCoverage">
      <Helmet>
        <title>Graphnile | Media Coverage</title>
      </Helmet>
      <Container>
        <h5>Media Coverage:</h5>
        <Row>
          <Col md={4} sm={12} xs={12}>
            <a
              href="https://riseconf.com/wp-content/uploads/2019/06/RISE-Indian-Startups.pdf"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={rise} />
                <Card.Body>
                  <Card.Title>RISE HONG KONG</Card.Title>
                  <Card.Text style={{ color: "grey" }}>
                    10 of the best startups selected to represent India at the
                    largest technology event in Asia.
                  </Card.Text>
                  <a>
                    <button className="btn">Read Article </button>
                  </a>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col md={4} sm={12} xs={12}>
            <a
              href="https://swiftnlifthealthcare.com/intro?title=10-Most-Promising-Consulting-Startups---2020&procode=i2039&id=i2039cover"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card style={{ width: "18rem" }}>
                <Card.Img
                  // style={{ height: "21vh" }}
                  variant="top"
                  src={media1}
                />
                <Card.Body>
                  <Card.Title>SwiftnLift</Card.Title>
                  <Card.Text style={{ color: "grey" }}>
                    10 Most Promising Consulting Firms - 2020
                    <br></br>
                  </Card.Text>
                  <a>
                    <button className="btn">Read Article</button>
                  </a>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col md={4} sm={12} xs={12}>
            <a
              href="https://www.moneycontrol.com/news/politics/karnataka-polls-2018-what-ai-based-analytics-unconventional-pollsters-are-predicting-2567841.html"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card style={{ width: "18rem" }}>
                <Card.Img
                  // style={{ height: "21vh" }}
                  variant="top"
                  src={media2}
                />
                <Card.Body>
                  <Card.Title>Money Control</Card.Title>
                  <Card.Text style={{ color: "grey" }}>
                    What Artifical Intelligence-based analytics, unconventional
                    pollsters are predicting.
                  </Card.Text>
                  <a>
                    <button className="btn">Read Article </button>
                  </a>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MediaCoverage;
