import React from "react";
import { Container } from "react-bootstrap";
import "./Form.css";
import useInput from "../use-input";

const FormItem = (props) => {
  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangedHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput((value) => value.trim() !== "");

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput((value) => value.includes("@"));

  let formIsValid = false;

  if (enteredNameIsValid && enteredEmailIsValid) {
    formIsValid = true;
  }
  if (enteredNameIsValid && enteredEmailIsValid) {
    formIsValid = true;
  }
  const formSubmissionHandler = (Event) => {
    Event.preventDefault();

    if (!enteredNameIsValid) {
      return;
    }

    resetNameInput();
    resetEmailInput();
  };

  return (
    <Container className="ashpak">
      <form onSubmit={formSubmissionHandler}>
        <label htmlFor="name">Full Name</label>
        <input
          type="text"
          id="name"
          value={enteredName}
          onChange={nameChangedHandler}
          onBlur={nameBlurHandler}
        ></input>
        {nameInputHasError && (
          <p style={{ color: "red" }}>Name Must Not Be Empty</p>
        )}

        <div>
          <label htmlFor="email">Your email</label>
          <input
            type="text"
            id="email"
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={enteredEmail}
          ></input>
          {emailInputHasError && (
            <p style={{ color: "red" }}>Email Must Have @</p>
          )}
        </div>
        <label for="message">Message</label>
        <textarea
          id="message"
          name="message"
          placeholder="write something"
          style={{ height: "200px", width: "100%" }}
        ></textarea>
        <div>
          <button className="btn" disabled={!formIsValid}>
            Submit
          </button>
        </div>
      </form>
    </Container>
  );
};

export default FormItem;
