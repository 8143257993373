import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Cards.css";

// import CardItem from "./CardItem";
import Card from "react-bootstrap/Card";
import image from "/Users/aicgim-imac/Desktop/webashpak/src/images/nikola-majksner-hXNGeAFOgT4-unsplash.jpg";
import image1 from "/Users/aicgim-imac/Desktop/webashpak/src/images/pexels-pixabay-41949.jpg";
import image2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/docusign-7RWBSYA9Rro-unsplash.jpg";
import image3 from "/Users/aicgim-imac/Desktop/webashpak/src/images/stephen-dawson-qwtCeJ5cLYs-unsplash.jpg";
import { Container, Row, Col } from "react-bootstrap";
// import image from "/Users/aicgim-imac/Desktop/webashpak/src/images/img-3.jpeg";
// import image1 from "/Users/aicgim-imac/Desktop/webashpak/src/images/img-2.jpeg";
// import image2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/img-6.jpeg";
// import image3 from "/Users/aicgim-imac/Desktop/webashpak/src/images/img-7.jpeg";
// import image4 from "/Users/aicgim-imac/Desktop/webashpak/src/images/img-5.jpeg";
function Cards() {
  return (
    <section className="cards">
      <Container expand={"sm" | "md" | "lg" | "xl" | "xxl"}>
        <Row style={{ paddingTop: "70px" }}>
          <Col md={3} sm={12} xs={12}>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/esgRating"
            >
              <Card className="card1" style={{}}>
                <Card.Img
                  style={{ height: "25vh" }}
                  variant="top"
                  src={image}
                />
                <Card.Body>
                  <Card.Title style={{ fontSize: "18px" }}>
                    ESG Ratings & Models
                  </Card.Title>
                  <Card.Text style={{ color: "grey" }}>
                    ESG models addresses concerns around environmental
                    sustainability and data governance. It offers a robust suite
                    of financial analytics and data science solutions.
                  </Card.Text>
                  <button className="btn">
                    Know More <i class="fa fa-arrow-right"></i>
                  </button>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={3} sm={12}>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="economicsResearch"
            >
              <Card className="card2" style={{}}>
                <Card.Img
                  style={{ height: "25vh" }}
                  variant="top"
                  src={image1}
                />
                <Card.Body>
                  <Card.Title style={{ fontSize: "18px" }}>
                    Economics Research
                  </Card.Title>
                  <Card.Text style={{ color: "grey" }}>
                    Our empirical thinking is the ultimate solution to your
                    problems. We provides rigorous, accessible and relevant
                    economic analysis to carve out success for tomorrow.
                  </Card.Text>
                  <button className="btn">
                    Know More <i class="fa fa-arrow-right"></i>
                  </button>
                </Card.Body>
              </Card>{" "}
            </Link>
          </Col>
          <Col md={3} sm={12}>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="restructureTurnaround"
            >
              <Card className="card3" style={{}}>
                <Card.Img
                  style={{ height: "25vh" }}
                  variant="top"
                  src={image2}
                />
                <Card.Body>
                  <Card.Title style={{ fontSize: "18px" }}>
                    Restructuring & Turnaround
                  </Card.Title>
                  <Card.Text style={{ color: "grey" }}>
                    Graphnile Advisory offers unbiased alternatives & creative
                    market-based solutions to restructuring on behalf of clients
                    to achieve a high proportion of out-of-court solutions.
                  </Card.Text>
                  <button className="btn">
                    Know More <i class="fa fa-arrow-right"></i>
                  </button>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={3} sm={12}>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/RegulatoryRisk"
            >
              {" "}
              <a style={{ textDecoration: "none", color: "black" }} href="#">
                <Card className="card4" style={{}}>
                  <Card.Img
                    style={{ height: "25vh" }}
                    variant="top"
                    src={image3}
                  />
                  <Card.Body>
                    <Card.Title style={{ fontSize: "18px" }}>
                      Regulatory & Risk Advisory
                    </Card.Title>
                    <Card.Text style={{ color: "grey" }}>
                      Model Development and Validation as per SR 11-7
                      Documentation, Credit Risk Modeling, enforcement trends
                      <br></br> Impairment Modeling and (CECL / IFRS9) Economic
                      Forecasts.
                    </Card.Text>
                    <button className="btn">
                      Know More <i class="fa fa-arrow-right"></i>
                    </button>
                  </Card.Body>
                </Card>{" "}
              </a>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Cards;
