import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import economic from "/Users/aicgim-imac/Desktop/webashpak/src/images/pexels-andrea-piacquadio-3756681.jpg";
import "./Finance.css";
const Finance = () => {
  return (
    <section className="restructuringTurnaround">
      <Container fluid className="restructuring ">
        <h4 style={{ paddingLeft: "20%", paddingTop: "240px", color: "black" }}>
          Graphnile Learning
        </h4>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Row>
          <Col md={6}>
            <img style={{ width: "100%" }} src={economic}></img>
          </Col>

          <Col md={6}>
            <h5>
              {" "}
              CERTIFICATION IN RESTRUCTURING AND DISTRESSED VALUATION FOR
              SPECIAL SITUATIONS
            </h5>
            <p style={{ color: "grey" }}>
              We are the team of highly qualified Quant professionals who have
              worked in investment banking, risk management, trading. Consulting
              with advanced qualifications such as PhD/Master’s in Finance,
              Economics, Machine Learning, Statistics, Mathematics and Financial
              Economics.
            </p>
          </Col>
        </Row>
        <Row className="row1" style={{ paddingTop: "20px" }}>
          <h5>Overview</h5>
          <p style={{ color: "grey" }}>
            Investments and transactions in Special Situations require technical
            knowledge of Bankruptcy, Restructuring and Distressed Valuation. To
            cater to these industry requirements, this certification has been
            designed in consultation with leading investment banks,
            restructuring & turnaround consulting firms, distressed debt funds
            and private equity funds. This certification combines a rigorous
            academic core with practical modelling by offering:
          </p>
          <ul style={{ marginLeft: "20px" }}>
            <li style={{ color: "grey" }}>
              Interactive classes to become familiar with key theoretical
              concepts in restructuring and turnaround.
            </li>
            <li style={{ color: "grey" }}>
              Modelling workshops to get proficient in distressed valuation in
              complex situations.
            </li>
            <li style={{ color: "grey" }}>
              Use of case studies to highlight nuances of different bankruptcy
              law regimes.
            </li>
            <li style={{ color: "grey" }}>
              Practitioner teaching to focus on skills demanded by industry.
            </li>
            <p style={{ color: "grey" }}>
              The course is conducted over a period of 10 months and is divided
              into 5 parts.
            </p>
          </ul>
        </Row>

        <Row className="row2" style={{ paddingTop: "20px" }}>
          <h5>Syllabus</h5>

          <ul style={{ marginLeft: "20px" }}>
            <li style={{ color: "grey" }}>Corporate Credit Analysis</li>
            <li style={{ color: "grey" }}>
              Financial statement analysis of distressed companies
            </li>
            <li style={{ color: "grey" }}>Capital structure analysis</li>
            <li style={{ color: "grey" }}>
              Creditworthiness of High Yield issues and debt capacity model
            </li>
            <li style={{ color: "grey" }}>
              Leveraged Finance analysis: Leverage multiples, key driver
              analysis, Sources & Uses (S&U) schedule
            </li>
            <li style={{ color: "grey" }}>Causes of Distress</li>
            <li style={{ color: "grey" }}>Turnarounds and workouts</li>
            <li style={{ color: "grey" }}></li>Distressed Valuation
            <li style={{ color: "grey" }}>
              Valuation methodologies for Fair Market Value (FMV), Orderly
              Liquidation Value (OLV), Forced Liquidation Value (FLV)
            </li>
            <li style={{ color: "grey" }}>
              Enterprise Value under absolute vs relative valuation and going
              concern vs liquidation
            </li>
            <li style={{ color: "grey" }}>
              Multiple-based valuation methodology
            </li>
            <li style={{ color: "grey" }}>Discounted cash flow methodology</li>
            <li style={{ color: "grey" }}>
              Fresh start accounting and financial reporting during
              reorganization
            </li>
            <li style={{ color: "grey" }}>
              Planning for liquidation or restructuring
            </li>
            <li style={{ color: "grey" }}>Distressed M&A</li>
            <li style={{ color: "grey" }}>Rescue financing and DIP loans</li>
            <li style={{ color: "grey" }}>Restructuring</li>
            <li style={{ color: "grey" }}>
              Overview of different bankruptcy law regimes
            </li>
            <li style={{ color: "grey" }}>
              Covenants: Conditions precedent, Representations, Warranties,
              incurrence vs. maintenance covenants
            </li>
            <li style={{ color: "grey" }}>
              Subordination: legal, structural and constructive subordination
            </li>
            <li style={{ color: "grey" }}>
              Payout distribution, Establishment of classes and interests
            </li>
            <li style={{ color: "grey" }}>Structuring credit documentation</li>
            <li style={{ color: "grey" }}>Tax analysis of bankruptcy</li>
            <li style={{ color: "grey" }}>
              Out-of-court Workouts, Prepackaged and Pre-arranged bankruptcies
            </li>
            <li style={{ color: "grey" }}>Modelling</li>
            <li style={{ color: "grey" }}>
              13 week cash flow modelling based on actual case studies
            </li>
            <li style={{ color: "grey" }}>Leveraged Buyout (LBO) Modeling</li>
            <li style={{ color: "grey" }}>
              3-statement bankruptcy model to performed distressed valuation and
              recovery analysis
            </li>
            <li style={{ color: "grey" }}>
              Recovery waterfall and scenario analysis
            </li>
          </ul>
        </Row>

        <Row className="row3" style={{ paddingTop: "20px" }}>
          <h5>Course schedule</h5>

          <p style={{ color: "grey" }}>
            The course is conducted over a period of 10 months and is divided
            into 5 parts.<br></br> Each part involves 20 hour of classroom
            instructions and a 2 hours assessment.<br></br> All classroom
            instructions are held over the weekend to accommodate working
            professionals.<br></br> Next Batch starting TBD.
            <br></br> Please email daniel.minsky@graphnile.com to enquire about
            the registration and fee.
          </p>
        </Row>
      </Container>
    </section>
  );
};

export default Finance;
