import "./App.css";
import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";

import Footer from "./components/Footer";

import "bootstrap/dist/css/bootstrap.min.css";
import EconomicsResearch from "./components/pages/EconomicsResearch";
import EsgRating from "./components/pages/EsgRating";
import RestructuringTurnaround from "./components/pages/RestructuringTurnaround";
import Nav2 from "./components/pages/Nav2";

import Form from "./components/pages/Form";
import ScrollToTop from "./components/ScrollToTop";
import ElectoralAnalytics from "./components/ElectoralAnalytics";
import PoliticalRisk from "./components/PoliticalRisk";
import Finance from "./components/Finance";
import AboutUs from "./components/AboutUs";
import BusniessFunctions from "./components/BusniessFunctions";
import MediaCoverage from "./components/MediaCoverage";
import GraphnileLearning from "./components/GraphnileLearning";
import Career from "./components/Career";
import RegulatoryRisk from "./components/pages/RegulatoryRisk";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/economicsResearch" element={<EconomicsResearch />} />
          <Route path="/esgRating" element={<EsgRating />} />
          <Route
            path="/restructureTurnaround"
            element={<RestructuringTurnaround />}
          />
          <Route path="/ourMission" element={<Nav2 />} />
          <Route path="/signup" element={<Form />} />
          <Route path="/electrolAnalysis" element={<ElectoralAnalytics />} />
          <Route path="/politicalRisk" element={<PoliticalRisk />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/busniessFunctions" element={<BusniessFunctions />} />
          <Route path="/mediaCoverage" element={<MediaCoverage />} />
          <Route path="/graphnileLearning" element={<GraphnileLearning />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/RegulatoryRisk" element={<RegulatoryRisk />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
