import React from "react";
import "./EsgRating.css";
import { Col, Container, Row } from "react-bootstrap";
import esg from "/Users/aicgim-imac/Desktop/webashpak/src/images/paul-summers-ArtVJ-217Cw-unsplash.jpg";
import { Helmet } from "react-helmet";
const EsgRating = () => {
  return (
    <section className="esgRating">
      <Helmet>
        <title>Graphnile | ESG Ratings & Climate Risk Ratings</title>
      </Helmet>
      <Container fluid className="esg ">
        <Container>
          <h4 style={{ paddingTop: "150px" }}>Esg Ratings & Models</h4>
        </Container>
      </Container>
      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Row>
          <Col md={6}>
            <img style={{ width: "100%" }} src={esg}></img>
          </Col>
          <Col md={6}>
            <h5>ESG Ratings & Models</h5>
            <p style={{ color: "grey" }}>
              Graphnile ESG Ratings are based on a rules-based methodology
              designed to measure the company's exposure to long-term,
              industry-related environmental, social and governance risks. We
              research and rate companies based on a scale of '1' to '10'
              depending on their exposure to industry-material ESG risks and
              their ability to manage those risks in comparison to their
              industry peers, using artificial intelligence, machine learning,
              and natural language processing.
            </p>
            <ul className="ulesg">
              <h6 style={{ marginLeft: "0" }}>
                ESG Ratings and Climate Risk Ratings
              </h6>
              <li style={{ color: "grey" }}>Climate Risk Scenarios</li>
              <li style={{ color: "grey" }}>Industry-specific Research</li>
              <li style={{ color: "grey" }}>
                Easy Integration into Existing Impairment Models (CECL / IFRS9)
              </li>
              <li style={{ color: "grey" }}>
                Model Development and Validation as per SR 11-7 Documentation
              </li>
              <li style={{ color: "grey" }}>
                Credit Risk Modeling - Impairment Modeling (CECL / IFRS9)
              </li>
              <li style={{ color: "grey" }}>
                Economic Forecasts and Scenario Management
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EsgRating;
