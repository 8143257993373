import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import "./BusniessFunctions.css";
import port from "/Users/aicgim-imac/Desktop/webashpak/src/images/thisisengineering-raeng-64YrPKiguAE-unsplash.jpg";
import port1 from "/Users/aicgim-imac/Desktop/webashpak/src/images/thought-catalog-UK78i6vK3sc-unsplash.jpg";
import port2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/campaign-creators-gMsnXqILjp4-unsplash.jpg";
import ash from "/Users/aicgim-imac/Desktop/webashpak/src/images/scott-graham-5fNmWej4tAA-unsplash.jpg";
import ash2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/headway-5QgIuuBxKwM-unsplash.jpg";
import image9 from "/Users/aicgim-imac/Desktop/webashpak/src/images/dylan-gillis-KdeqA3aTnBY-unsplash.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const BusniessFunctions = () => {
  return (
    <section className="busniessFunctions">
      <Helmet>
        <title>Graphnile | Business Functions</title>
      </Helmet>
      <Container style={{ paddingBottom: "100px", paddingTop: "80px" }}>
        <Row className="helloworld">
          {" "}
          <Col md={4}>
            <img style={{ width: "100%" }} src={image9}></img>
          </Col>
          <Col className="second" md={8}>
            <h4 style={{}}>Busniess Functions</h4>
            <p style={{ color: "grey" }}>
              As a strategic advisory firm, we help debtors execute
              restructuring and turnaround plans, and help banks develop robust
              credit risk management systems. Graphnile has been developing
              innovative solutions of high quality and relevance, in response to
              the changing needs of global investors.
            </p>

            <button className="btn">
              {" "}
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/ourMission"
              >
                Read More{" "}
              </Link>
            </button>
          </Col>
        </Row>
        <h6 style={{ paddingTop: "20px" }}>Solutions Offered By Graphnile:</h6>
        <Row style={{ paddingTop: "20px" }}>
          <Col className="ashpaknadaf" md={4}>
            <Link
              to="/economicsResearch"
              style={{ textDecoration: "none", color: "black" }}
            >
              {" "}
              <div className="media">
                <img
                  style={{ width: "200px", marginLeft: "0px" }}
                  className="mr-3"
                  src={image9}
                  alt="Generic placeholder image"
                />

                <div className="media-body">
                  <h5 style={{ paddingTop: "25px" }} className="mt-0">
                    Economics Research
                  </h5>
                  <a
                    href="#"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Read More <i class="fa fa-arrow-right"></i>
                  </a>{" "}
                </div>
              </div>
            </Link>
          </Col>
          <Col className="ashpaknadaf1" md={4}>
            <Link
              to="/politicalRisk"
              style={{ textDecoration: "none", color: "black" }}
            >
              {" "}
              <div className="media">
                <img
                  style={{ width: "200px" }}
                  className="mr-3"
                  src={ash}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 style={{ paddingTop: "25px" }} className="mt-0">
                    Political Risk Analysis and Public Policy Research
                  </h5>
                  <a
                    href="#"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Read More <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Link>
          </Col>
          <Col className="ashpaknadaf2" md={4}>
            <Link
              to="/restructureTurnaround"
              style={{ textDecoration: "none", color: "black" }}
            >
              {" "}
              <div className="media">
                <img
                  style={{ width: "200px" }}
                  className="mr-3"
                  src={ash2}
                  alt="Generic placeholder image"
                />{" "}
                <div className="media-body">
                  <h5 style={{ paddingTop: "25px" }} className="mt-0">
                    Restructuring & Turnaround
                  </h5>
                  <a
                    href="#"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Read More <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col className="ashpak1" md={4}>
            <Link
              to="/RegulatoryRisk"
              style={{ textDecoration: "none", color: "black" }}
            >
              {" "}
              <div className="media">
                <img
                  style={{ width: "200px" }}
                  className="mr-3"
                  src={port2}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 style={{ paddingTop: "25px" }} className="mt-0">
                    Regulatory & Risk Advisory
                  </h5>
                  <a
                    href="#"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Read More <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>{" "}
            </Link>
          </Col>
          <Col className="ashpak2" md={4}>
            <Link
              to="/esgRating"
              style={{ textDecoration: "none", color: "black" }}
            >
              {" "}
              <div className="media">
                <img
                  style={{ width: "200px" }}
                  className="mr-3"
                  src={port1}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 style={{ paddingTop: "25px" }} className="mt-0">
                    ESG Ratings & Models
                  </h5>
                  <a
                    href="#"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Read More <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Link>
          </Col>
          <Col className="ashpak3" md={4}>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/electrolAnalysis"
            >
              {" "}
              <div className="media">
                <img
                  style={{ width: "200px" }}
                  className="mr-3"
                  src={port}
                  alt="Generic placeholder image"
                />{" "}
                <div className="media-body">
                  <h5 style={{ paddingTop: "25px" }} className="mt-0">
                    Electoral Analytics
                  </h5>
                  <a
                    href="#"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Read More <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>

    // <div>
    /* <section className="busniessFunctions"> */
    // <Container style={{ paddingBottom: "70px" }}>
    //   <Row>
    //     <Col md={6} sm={6}>
    //       <h1>
    //         ghfeegwfhwefgwhefgfhwfghdsfsdffdsffdsfssdsdddsefgfddggfgregergrgeggerggreggrggreggfgdggfgdggdgfdggfd
    //       </h1>
    //     </Col>

    //     <Col md={6} sm={6}>
    //       <h1>
    //         fgefefhfwegfhfgfhwfgwergrfdgdffddfgfgfsgdfgdfgdfdgsfgffgsfhfghsfgdhfsgfhsdfghfgdhsfgdshg
    //       </h1>
    //     </Col>
    //   </Row>
    // </Container>
    /* </section> */
    /* </div> */
  );
};

export default BusniessFunctions;
