import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import map from "/Users/aicgim-imac/Desktop/webashpak/src/images/G.jpg";

const Map = () => {
  return (
    <section className="map">
      <Container>
        <h5>Our Global Footprint:</h5>
        <Row>
          <Col md={9}>
            <img style={{ width: "90%" }} src={map}></img>
          </Col>
          <Col md={3}>
            <i
              style={{ fontSize: "25px" }}
              class="fa fa-map-marker"
              aria-hidden="true"
            ></i>
            <h4>Location</h4>
            <h5>Visit Our Office</h5>
            <p style={{ color: "grey" }}>
              Graphnile Advisory Inc. <br></br>1315 Pickering Parkway, Suite
              203, Pickering, Ontario, Canada | L1V 7G5
            </p>
            <br></br>

            <i
              style={{ fontSize: "25px" }}
              class="fa fa-phone"
              aria-hidden="true"
            ></i>
            <h5>Make A Call</h5>
            <p style={{ color: "grey" }}> (+1) 647 558 5565</p>
            <br></br>

            <i
              style={{ fontSize: "25px" }}
              class="fa fa-envelope"
              aria-hidden="true"
            ></i>
            <h5>Send An Email</h5>
            <p style={{ color: "grey" }}>info@graphnile.com</p>
            <p style={{ color: "grey" }}>support@graphnile.com</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Map;
