import React from "react";
import "./Footer.css";
import "./Button.css";
import { Link } from "react-router-dom";
import footerimg from "/Users/aicgim-imac/Desktop/webashpak/src/images/logo.png";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription"></section>
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2 style={{ fontSize: "20px" }}>UseFul Links</h2>
            <Link to="/aboutus">About Us</Link>
            <Link to="/busniessFunctions">Business Functions</Link>
            <Link to="/mediaCoverage">Media Coverage</Link>
            <Link to="/graphnileLearning">Graphnile Learning</Link>
            <Link to="/careers">Career</Link>
          </div>
          <div class="footer-link-items">
            <h2 style={{ fontSize: "20px" }}>Services</h2>
            <Link to="/economicsResearch">Economics Research</Link>
            <Link to="/restructureTurnaround">
              Turnaround and Restructuring{" "}
            </Link>
            <Link to="/politicalRisk">Political Risk Analysis</Link>
            <Link to="/">Public Policy Research</Link>
            <Link to="/electrolAnalysis">
              Electoral Analytics Risk Modeling
            </Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2 style={{ fontSize: "20px" }}>Contact</h2>
            <h5 style={{ color: "white" }}>Phone:</h5>
            <Link to="/"> (+ 1) 647 558 5565</Link>
            <hr></hr>
            <h5 style={{ color: "white" }}>Email:</h5>
            <Link to="/">info@graphnile.com</Link>
            <hr></hr>
            <h5 style={{ color: "white" }}>Address:</h5>
            <p style={{ color: "white" }}>
              Graphnile Advisory Inc. 1315 Pickering Parkway, Suite 203,
              Pickering, Ontario, Canada | L1V 7G5
            </p>
          </div>
          <div class="footer-link-items">
            <h2 style={{ fontSize: "20px" }}>Social Media</h2>
            <Link to="/">
              <i style={{ fontSize: "30px" }} class="fab fa-facebook"></i>
            </Link>
            <Link to="/">
              <i style={{ fontSize: "30px" }} class="fab fa-instagram"></i>
            </Link>
            <Link to="/">
              <i style={{ fontSize: "30px" }} class="fab fa-twitter"></i>
            </Link>
            <Link to="/">
              <i style={{ fontSize: "30px" }} class="fab fa-youtube"></i>
            </Link>
          </div>
        </div>
      </div>
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="footer-logo">
            <Link to="/" className="social-logo">
              <img style={{ width: "200px" }} src={footerimg}></img>
            </Link>
          </div>
          <small style={{ marginLeft: "10px" }} class="website-rights">
            © 2022 Graphnile. All Rights Reserved.
          </small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
