import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";
import video from "/Users/aicgim-imac/Desktop/webashpak/src/videos/vecteezy_earth-1008-the-planet-earth-in-space-as-night-falls-over-europe-and-north-america-and-city-lights-turn-on_1624061.mp4";
import video1 from "/Users/aicgim-imac/Desktop/webashpak/src/videos/Trees_And_Grass_In_The_Park_0922.mp4";
import video2 from "/Users/aicgim-imac/Desktop/webashpak/src/videos/young_tree_earth_dollar_money.mp4";
import image from "/Users/aicgim-imac/Desktop/webashpak/src/images/img-3.jpeg";
import image1 from "/Users/aicgim-imac/Desktop/webashpak/src/images/pexels-min-an-675937.jpg";
import image2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/pexels-daniel-frank-572937.jpg";
import { Container, Row } from "react-bootstrap";
import "./HeroSection.css";
import { Link } from "react-router-dom";
const HeroSection = () => {
  return (
    <>
      <Carousel fade={true} pause={false}>
        <Carousel.Item interval={2000}>
          <img
            style={{ height: "90vh" }}
            className="d-block w-100"
            src={image1}
            alt="First slide"
          />
          <Carousel.Caption className="hello">
            <h3
              style={{
                fontSize: "40px",
                textAlign: "center",
              }}
            >
              REGULATORY & RISK ADVISORY
            </h3>
            <p style={{ fontSize: "18px" }}>
              Model Development and Validation as per SR 11-7 Documentation,
              Credit Risk Modeling,<br></br> Impairment Modeling (CECL / IFRS9)
              Economic Forecasts.
              <br></br>
              <br></br>
              <Link style={{ textDecoration: "none" }} to="/RegulatoryRisk">
                {" "}
                <button className="btnn">Discover More</button>{" "}
              </Link>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            style={{ height: "90vh" }}
            className="d-block w-100"
            src={image2}
            alt="Third slide"
          />
          <Carousel.Caption className="hello">
            <h3 style={{ fontSize: "40px", textAlign: "center" }}>
              ESG RATINGS & MODELS
            </h3>
            <p style={{ fontSize: "18px" }}>
              Graphnile ESG Ratings can be integrated into investment decision
              making to identify risks and<br></br>opportunities that are not
              captured in conventional financial analysis.
              <br></br>
              <br></br>
              <Link style={{ textDecoration: "none" }} to="/esgRating">
                {" "}
                <button className="btnn">Discover More</button>{" "}
              </Link>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            style={{ height: "90vh" }}
            className="d-block w-100"
            src={image}
            alt="Third slide"
          />
          <Carousel.Caption className="hello">
            <h3 style={{ fontSize: "40px" }}>
              ALTERNATIVE DATA FOR FUNDAMENTAL RESEARCH
            </h3>
            <p style={{ fontSize: "18px" }}>
              There is no such thing as an obscure data set as long as it
              contains
              <br></br> actionable information.
              <br></br>
              <br></br>
              <Link style={{ textDecoration: "none" }} to="/aboutUs">
                {" "}
                <button className="btnn">Discover More</button>{" "}
              </Link>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default HeroSection;
