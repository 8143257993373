import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import economic from "/Users/aicgim-imac/Desktop/webashpak/src/images/pexels-edmond-dantès-7103151.jpg";
import "./ElectoralAnalytics.css";
import { Helmet } from "react-helmet";
const ElectoralAnalytics = () => {
  return (
    <section className="restructuringTurnaround">
      <Helmet>
        <title>Graphnile | Electoral Analytics</title>
      </Helmet>
      <Container fluid className="restructuring ">
        <h4 style={{ paddingLeft: "20%", paddingTop: "240px", color: "black" }}>
          Electoral Analytics
        </h4>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Row>
          <Col md={6}>
            <img style={{ width: "100%" }} src={economic}></img>
          </Col>

          <Col md={6}>
            <h5> Electoral Analytics</h5>
            <p style={{ color: "grey" }}>
              Graphnile’s Electoral Analytics Consulting Solution (EACS) caters
              to both the Election authorities as well as Political campaign
              managers .Roping in the technologies of big data and Analytical
              modelling EACS provides the ability to the election authorities to
              enhance the credibility of the polling exercise and provides a
              coherent product which is agreeable to all stakeholders. EACS
              using the methodology of network voting expertise, business case
              development on the back of decisive cutting edge research, by
              automating electoral operations provides election officers with
              indispensable consultation and information services. EACS uses
              state of the art statistical and machine learning algorithms
              provides campaign managers with robustly customised
              micro-targeting voter models for individual identification which
              form the backbone of any political campaign.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ElectoralAnalytics;
