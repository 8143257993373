import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AboutUs.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const AboutUs = () => {
  return (
    <section className="aboutUsSection">
      <Helmet>
        <title>Graphnile | Home</title>
      </Helmet>
      <Container>
        <Row>
          <Col
            style={{ marginTop: "200px" }}
            md={12}
            sm={12}
            xs={12}
            className="hi"
          >
            <h1 style={{ color: "white" }}>About Us </h1>
            <p style={{ color: "white" }}>
              Graphnile aims to support businesses in making insightful
              decisions by leveraging accurate data.
            </p>
            <p style={{ fontSize: "15px", color: "white" }}>
              Businesses across the globe are increasingly relying on their data
              to power their everyday operations, which makes better data
              management a top directive for leading enterprises. At Graphnile
              we believe that data accuracy will increase business efficiency
              and lowers outcome risks. Businesses can improve their entire
              decision-making process with reliable data outputs and mitigate
              risks along the way.
            </p>
            <Link style={{ textDecoration: "none" }} to="/nav2">
              <p
                style={{
                  fontSize: "20px",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Discover More
              </p>{" "}
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
