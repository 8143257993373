import React from "react";
import "./Nav2.css";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import vision from "/Users/aicgim-imac/Desktop/webashpak/src/images/2.jpg";
import mission from "/Users/aicgim-imac/Desktop/webashpak/src/images/3.jpg";
import value from "/Users/aicgim-imac/Desktop/webashpak/src/images/1.jpg";
import important from "/Users/aicgim-imac/Desktop/webashpak/src/images/amy-hirschi-b3AYk8HKCl0-unsplash.jpg";
import important1 from "/Users/aicgim-imac/Desktop/webashpak/src/images/thisisengineering-raeng-TXxiFuQLBKQ-unsplash.jpg";
import important2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/jehyun-sung-6U5AEmQIajg-unsplash.jpg";
import important3 from "/Users/aicgim-imac/Desktop/webashpak/src/images/erik-mclean-aielvGxZB0g-unsplash.jpg";
import important4 from "/Users/aicgim-imac/Desktop/webashpak/src/images/may-gauthier-0J9l9xRyMSo-unsplash.jpg";
import important5 from "/Users/aicgim-imac/Desktop/webashpak/src/images/mika-baumeister-Wpnoqo2plFA-unsplash.jpg";
import map2 from "/Users/aicgim-imac/Desktop/webashpak/src/images/G.jpg";
import { Helmet } from "react-helmet";
const Nav2 = () => {
  return (
    <section style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Graphnile | Our Mission</title>
      </Helmet>
      <Navbar style={{ backgroundColor: "black" }} expand="lg" sticky="top">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link style={{ color: "grey" }} href="#howDoWeResearch">
                How We Research
              </Nav.Link>
              <Nav.Link style={{ color: "grey" }} href="#ourMission">
                Our Mission
              </Nav.Link>
              <Nav.Link style={{ color: "grey" }} href="#partners">
                Partners
              </Nav.Link>
              <Nav.Link style={{ color: "grey" }} href="#whyGraphnile">
                Why Graphnile
              </Nav.Link>
              <Nav.Link style={{ color: "grey" }} href="#mapAboutUs">
                Our Global Footprint
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <section id="whoWeAre">
          <Row className="nadaf1" style={{ marginTop: "60px" }}>
            <h4>Who We Are</h4>
            <p style={{ color: "grey" }}>
              At our core we are machine learning algorithm enthusiast. We
              relentlessly strive to design the best possible algorithm because
              data matures like wine and algorithms like fish. Graphnile might
              not be what you expect but it is what you need. We will tell you
              how to turn your data into information, information into insights
              and insights into better decisions. We specialize in economics
              research, financial modelling, political risk analysis, electoral
              analytics and cyber security. We leverage data and take an
              interdisciplinary approach to providing the solutions. We apply
              the concepts of engineering, physics, statistics, econometrics and
              computer science to data analysis to deliver the optimal solution.
            </p>
            <h6>Our Philosophy:</h6>
            <p style={{ color: "grey" }}>
              We trust in Empiricism. Empiricism is the philosophy of science
              which emphasizes evidences.
            </p>
            <h6>What we believe:</h6>
            <p style={{ color: "grey" }}>
              At Graphnile we believe that the world is one big data problem and
              machine learning algorithms holds the answers. We know that data
              is the oil of the 21st century, and machine learning is the
              fastest turbocharged formula-one car ever made.
            </p>
          </Row>
        </section>
        <section id="howDoWeResearch">
          <Row className="nadaf2" style={{ marginTop: "60px" }}>
            <h4>How Do We Research</h4>
            <h6>We let data to confess</h6>
            <p style={{ color: "grey" }}>
              Data has feeling which confess something. Our expert’s senses are
              efficacious enough to hear all the data confession. You can trust
              us. We have enough analytic tools, sufficient staff, well defined
              process and expertise to extract real value from your analytic
              investments.
            </p>

            <h6>Data-driven mindset</h6>
            <p style={{ color: "grey" }}>
              Without analytics, the company is vulnerable. When you’re battling
              for a competitive advantage in business, data analysis is most
              important for your success. We are careful enough while analyzing
              your data. Our experts take appropriate monitoring or checking of
              results to avoid any teasing from superficial insights. However we
              never stop analysis prematureky at just symptoms when the root
              cause of problem remains at large.
            </p>
            <h6>Actionable Data Insight</h6>
            <p style={{ color: "grey" }}>
              We remove the noise when analyzing data and isolate what matters.
              With most companies collecting large amounts of data, we need to
              be both talented and disciplined to pinpoint key insights that can
              yield value and we have prove our mettle in that. We offer wide
              range of analytic solutions from real time data analysis and
              fueling evidence based answers.
            </p>
          </Row>

          <section id="ourMission">
            <Row className="nadaf3" style={{ marginTop: "60px" }}>
              <h4>Our Mission</h4>
              <Col style={{ marginTop: "20px" }} md={4}>
                <img style={{ width: "100%" }} src={vision}></img>
              </Col>
              <Col style={{ marginTop: "20px" }} md={4}>
                <img style={{ width: "100%" }} src={mission}></img>
              </Col>
              <Col style={{ marginTop: "20px" }} md={4}>
                <img style={{ width: "100%" }} src={value}></img>
              </Col>
            </Row>
          </section>
          <section id="partners">
            <Container className="nadaf6">
              <Row style={{ marginTop: "60px" }}>
                <h4>Partners</h4>
                <p style={{ color: "grey", marginTop: "20px" }}>
                  Our services and business solutions are strengthened by
                  alliances with leading partners across the globe. Our network
                  of alliance and teaming relationships creates business value,
                  reduces risk and accelerates growth. We work with our partners
                  to boost their revenue growth, expand markets and geographic
                  reach, facilitate sales process, and enhance service
                  offerings. Graphnile and alliance partners jointly deliver
                  business solutions that address our clients’ business
                  problems. We address specific client needs as well as develop
                  tools and methods to accelerate the success.
                </p>
              </Row>
            </Container>
          </section>
          <section id="whyGraphnile">
            <Container className="nadaf4">
              <Row style={{ marginTop: "60px" }}>
                <h4>Why Graphnile?</h4>
                <Col md={4}>
                  <div className="media">
                    <img
                      style={{
                        width: "150px",
                        marginLeft: "0px",

                        height: "150px",
                        paddingTop: "20px",
                      }}
                      className="mr-3"
                      src={important}
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 style={{ paddingTop: "20px" }} className="mt-0">
                        Customer Success
                      </h5>
                      <p style={{ color: "grey" }}>
                        We provide data insights to increase customer visibility
                        and reduce support and education cost.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="media">
                    <img
                      style={{
                        width: "150px",
                        marginLeft: "0px",

                        height: "150px",
                        paddingTop: "20px",
                      }}
                      className="mr-3"
                      src={important1}
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 style={{ paddingTop: "20px" }} className="mt-0">
                        Product Management
                      </h5>
                      <p style={{ color: "grey" }}>
                        It is our professionals' knowledge and experience that
                        generates decisive results.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="media">
                    <img
                      style={{
                        width: "150px",
                        marginLeft: "0px",

                        height: "150px",
                        paddingTop: "20px",
                      }}
                      className="mr-3"
                      src={important2}
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 style={{ paddingTop: "20px" }} className="mt-0">
                        Executive Leadership
                      </h5>
                      <p style={{ color: "grey" }}>
                        Bring product data to strategy discussions on board
                        presentation.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="media">
                    <img
                      style={{
                        width: "150px",
                        marginLeft: "0px",

                        height: "150px",
                        paddingTop: "40px",
                      }}
                      className="mr-3"
                      src={important3}
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 style={{ paddingTop: "40px" }} className="mt-0">
                        Comprehensive Services
                      </h5>
                      <p style={{ color: "grey" }}>
                        In addition to our standalone offerings, we also provide
                        our clients with comprehensive solutions that address a
                        variety of interrelated issues.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="media">
                    <img
                      style={{
                        width: "150px",
                        marginLeft: "0px",

                        height: "150px",
                        paddingTop: "40px",
                      }}
                      className="mr-3"
                      src={important4}
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 style={{ paddingTop: "40px" }} className="mt-0">
                        Philosophy of Empiricism
                      </h5>
                      <p style={{ color: "grey" }}>
                        We believe that data powers everything that we do. We
                        know how to turn your data into information, information
                        into insights and insights into better decisions.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="media">
                    <img
                      style={{
                        width: "150px",
                        marginLeft: "0px",

                        height: "150px",
                        paddingTop: "40px",
                      }}
                      className="mr-3"
                      src={important5}
                      alt="Generic placeholder image"
                    />
                    <div className="media-body">
                      <h5 style={{ paddingTop: "40px" }} className="mt-0">
                        Alternative Data
                      </h5>
                      <p style={{ color: "grey" }}>
                        We use Alternative data for fundamental research. We are
                        one of the leading providers of alternative data
                        insights in the world.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section id="mapAboutUs">
            <Container style={{ paddingTop: "60px" }}>
              <h5>Our Global Footprint:</h5>
              <Row>
                <Col md={12} sm={12}>
                  <img
                    style={{
                      width: "800px",
                      marginLeft: "18%",
                      paddingTop: "30px",
                    }}
                    src={map2}
                  ></img>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
      </Container>
    </section>
  );
};

export default Nav2;
