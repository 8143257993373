import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import economic from "/Users/aicgim-imac/Desktop/webashpak/src/images/marga-santoso-OmPqCwX422Y-unsplash.jpg";
import "./RestructuringTurnaround.css";
import { Helmet } from "react-helmet";
const RestructuringTurnaround = () => {
  return (
    <section className="restructuringTurnaround">
      <Helmet>
        <title>Graphnile | Restructuring & Turnaround</title>
      </Helmet>
      <Container fluid className="restructuring ">
        <Container>
          <h4 style={{ paddingTop: "150px" }}>Restructuring & Turnaround</h4>
        </Container>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Row>
          <Col md={6}>
            <img style={{ width: "100%" }} src={economic}></img>
          </Col>

          <Col md={6}>
            <h5>Restructuring & Turnaround</h5>
            <p style={{ color: "grey" }}>
              Restructuring & Turnaround Graphnile Advisory provides significant
              experience and a full range of services to companies and creditors
              facing special situations such as covenant compliance issues,
              liquidity challenges, or maturing debt. Solving these complex
              financial restructuring situations requires timely, flexible and
              creative strategic and tactical advice designed to create multiple
              options and to preserve value for all parties. Graphnile Advisory
              offers unbiased alternatives and creative market-based solutions
              to restructuring on behalf of clients to achieve a high proportion
              of out-of-court solutions. Our team leverages relationships with
              alternative capital providers and various global financial
              institutions to assist debtors and creditors alike. Our
              entrepreneurial and highly strategic approach to restructuring,
              separates Graphnile Advisory from strictly financial
              “re-engineers,” we exhaust a full range of alternatives.
            </p>
          </Col>
        </Row>

        <Row className="row1" style={{ paddingTop: "20px" }}>
          <h5>Company Advisory</h5>
          <p style={{ color: "grey" }}>
            Graphnile Advisory Restructuring team provides capable advice and
            actionable strategies in analyzing, structuring, negotiating, and
            executing on Restructuring solutions for middle market companies.
          </p>
          <ul style={{ marginLeft: "20px" }}>
            <li style={{ color: "grey" }}>Strategic Alternative Advisory</li>
            <li style={{ color: "grey" }}>
              Financial Restructuring / Capital Structure Advisory
            </li>
            <li style={{ color: "grey" }}>
              Negotiating agreements (Covenant relief, Amend and Extend)
            </li>
            <li style={{ color: "grey" }}>
              Distressed M&A / Chapter 11 363 sales
            </li>
            <li style={{ color: "grey" }}>
              Liability Management and Exchange Offers
            </li>
            <li>
              Debt & Equity capital raising (Recapitalization, Rescue, DIP, Jr.
              Debt, alternative financing)
            </li>
            <li style={{ color: "grey" }}>Pre-Packaged Chapter 11</li>
            <li style={{ color: "grey" }}>
              Chapter 11 Enterprise Valuation Reports
            </li>
          </ul>
        </Row>

        <Row className="row2" style={{ paddingTop: "20px" }}>
          <h5>Creditor Advisory</h5>
          <p style={{ color: "grey" }}>
            Graphnile Advisory services include initiating, negotiating,
            structuring and preserving asset value on behalf of creditors.
          </p>
          <ul style={{ marginLeft: "20px" }}>
            <li style={{ color: "grey" }}>
              Debt & Equity capital raising (Recapitalization, Rescue, DIP,
              Junior & Mezzanine Debt, alternative financing)
            </li>
            <li style={{ color: "grey" }}>
              Workout & Distressed M&A / Chapter 11 363 sales
            </li>
            <li style={{ color: "grey" }}>In-Court Enterprise Valuation</li>
            <li style={{ color: "grey" }}>Fairness Opinions</li>
            <li style={{ color: "grey" }}>Receiver Services</li>
            <li style={{ color: "grey" }}>13-Week Cash Flow Services</li>
          </ul>
        </Row>

        <Row className="row3" style={{ paddingTop: "20px" }}>
          <h5>Practice Area Specialties</h5>

          <ul style={{ marginLeft: "20px" }}>
            <li style={{ color: "grey" }}>Hotel / Hospitality and Gaming</li>
            <li style={{ color: "grey" }}>Restaurant & Retail</li>
            <li style={{ color: "grey" }}>Manufacturing and Industrials</li>
            <li style={{ color: "grey" }}>Transportation</li>
          </ul>
        </Row>
      </Container>
    </section>
  );
};

export default RestructuringTurnaround;
