import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import economic from "/Users/aicgim-imac/Desktop/webashpak/src/images/marga-santoso-OmPqCwX422Y-unsplash.jpg";
import "./EconomicResearch.css";
const EconomicsResearch = () => {
  return (
    <section className="economicsResearch">
      <Container fluid className="economics ">
        <Container>
          <h4 style={{ paddingTop: "150px" }}>Economics Research</h4>
        </Container>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Row>
          <Col md={6}>
            <img style={{ width: "100%" }} src={economic}></img>
          </Col>

          <Col md={6}>
            <h6>ECONOMICS RESEARCH</h6>
            <p style={{ color: "grey" }}>
              If you are the strategic planner and policy makers in business and
              government than you deserve right knowledge. Our empirical
              thinking is the ultimate solution to your problems. We provides
              rigorous, accessible and relevant economic analysis to carve out
              success for tomorrow. We are doing work that we are interested in
              and proud of in advance structural modeling and regression
              analysis, numerical techniques, time series analysis, forecasting
              and simulations. We are the ones who are challenging the status
              quo, from our strategy through to data analysis across all aspects
              of the Economic landscape. Our deep expertise in formulating and
              implementing empirical analysis to respond to the economic and
              financial issues drives change within the best businesses
              today.Our dynamic one-stop solution that integrates economic
              research and strategy with structured and unstructured datasets
              collected.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EconomicsResearch;
