import "../../App.css";
import HeroSection from "../HeroSection";
import React from "react";
import Cards from "../Cards";
import AboutUs from "../AboutUs";
import BusniessFunctions from "../BusniessFunctions";

import MediaCoverage from "../MediaCoverage";
import GraphnileLearning from "../GraphnileLearning";
import Career from "../Career";
import Map from "../Map";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Graphnile | Home</title>
      </Helmet>
      <HeroSection />
      <Cards />

      <BusniessFunctions />
      <MediaCoverage />
      <GraphnileLearning />
      <Career />
      <Map />
      <AboutUs />
    </>
  );
}

export default Home;
