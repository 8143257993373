import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";

import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "/Users/aicgim-imac/Desktop/webashpak/src/images/logo.png";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <Container>
        <nav className="navbar">
          <div className="navbar-container">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img src={logo}></img>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? "fa fa-times" : "fa fa-bars"} />
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/aboutus"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/busniessFunctions"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Business Functions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/mediaCoverage"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Media Coverage
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/graphnileLearning"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Graphnile Learning
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/careers"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Careers
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </Container>
    </>
  );
}

export default Navbar;
