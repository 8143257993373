import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import economic from "/Users/aicgim-imac/Desktop/webashpak/src/images/pexels-kampus-production-8353802.jpg";
import "./PoliticalRisk.css";
import { Helemt } from "react-helmet";
import Helmet from "react-helmet";
const PoliticalRisk = () => {
  return (
    <section className="restructuringTurnaround">
      <Helmet>
        <title>Graphnile | Political Risk & Public Policy Research</title>
      </Helmet>
      <Container fluid className="restructuring ">
        <Container>
          <h4 style={{ paddingTop: "150px" }}>
            Political Risk Analysis and Public Policy Research
          </h4>
        </Container>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Row>
          <Col md={6}>
            <img style={{ width: "100%" }} src={economic}></img>
          </Col>

          <Col md={6}>
            <h5> Political Risk Analysis and Public Policy Research</h5>
            <p style={{ color: "grey" }}>
              In the age of globalization and spurred by technological advances
              in communication as well as financial capital, corporations
              worldwide are riding the bandwagon of opportunities provided by
              the international liberalized market. But in an increasingly
              non-polar world, recognizing and predicting the inclinations as
              well as motivations for the national governments is of central
              importance to business outcomes. An increasingly emerging
              worrisome trend has seen the inability of the Corporations
              worldwide to mitigate this political risk persuasively. We at
              Graphnile are sensitized to these political risks as well as
              ensuing contingencies and recognize the importance of a coherent
              services for our clients which are directed at the threats arising
              from the political, social, operational and security landscape in
              International markets. This complex and extensive task is
              undertaken by our world renowned team at Policy Advisory
              Consulting Board (PACB). PACB Consultants provide precise and
              nonpartisan policy research which covers strategic, diplomatic and
              security issues of national or International importance. The
              conglomerate of PACB consultants comes from multifaceted
              backgrounds such as media, advertising, finance and academia
              encapsulating valuable international expertise with them. PACB
              consultants combine their Policy expertise with state of the art
              research skills which uses proven evaluation methodologies to
              deliver results and recommendations which cater to client specific
              demands. PACB works with diverse partners and collaborators across
              the nation and our international Partners in this corresponding
              space to test and provide evidence for the effectiveness of the
              final product.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PoliticalRisk;
