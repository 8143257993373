import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import career from "/Users/aicgim-imac/Desktop/webashpak/src/images/alex-kotliarskyi-QBpZGqEMsKg-unsplash.jpg";
// import SignUp from "./pages/SignUp";
import { Helmet } from "react-helmet";
const Career = () => {
  return (
    <section
      style={{ paddingTop: "70px", paddingBottom: "70px" }}
      className="career"
    >
      <Helmet>
        <title>Graphnile | Careers</title>
      </Helmet>
      <Container>
        <Row>
          <Col style={{ backgroundColor: "#094b88" }} md={3}>
            <h4
              style={{
                marginLeft: "60px",
                marginTop: "60%",
                color: "lightgray",
              }}
            >
              Careers At Graphnile
            </h4>
          </Col>
          <Col style={{ paddingLeft: "0px" }} md={6}>
            <img style={{ width: "100%", height: "40vh" }} src={career}></img>
          </Col>
          <Col md={3}>
            <p>
              Working at Graphnile, goes far beyond just having a job to make
              ends meet. With a job at our company, you have exciting new
              possibilities to follow your curiosity wherever it takes you. Our
              people are driven by their passion to make a meaningful difference
              in the world, to their families and friends, and to themselves.
            </p>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/signup"
            >
              <button className="btn">Contact Us</button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Career;
